import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Faq from "react-faq-component";
import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import ScrollDivider from "../components/ScrollDivider";
import Content, { HTMLContent } from "../components/Content";

import "./vragen.css";

// eslint-disable-next-line
export const VragenPageTemplate = ({
  heading,
  color,
  vragen,
  contentComponent,
  content,
}) => {
  const data = {
    rows: vragen,
  };
  const PageContent = contentComponent || Content;

  return (
    <div>
      <section
        className="section section--gradient"
        style={{ background: color }}
      >
        <div className="container" id="main">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered is-uppercase">
                        {heading}
                      </h3>
                      <PageContent
                        className="theme-italic has-text-centered"
                        content={content}
                      />
                      <hr className="solid" />
                      <Faq
                        data={data}
                        styles={{ bgColor: color, arrowColor: "white" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

VragenPageTemplate.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  color: PropTypes.string,
  vragen: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
};

const VragenPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const heroImage = getImage(frontmatter.image) || frontmatter.image;

  return (
    <Layout>
      <FullWidthImage
        img={heroImage}
        title={frontmatter.title}
        height={"90vh"}
        color={frontmatter.color}
        imgPosition={"center 70%"}
      />
      <ScrollDivider
        color={frontmatter.color}
        button={true}
        anchor="vragen#main"
      />
      <VragenPageTemplate
        contentComponent={HTMLContent}
        content={data.markdownRemark.html}
        heading={frontmatter.heading}
        color={frontmatter.color}
        vragen={frontmatter.vragen}
      />
    </Layout>
  );
};

VragenPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default VragenPage;

export const vragenPageQuery = graphql`
  query VragenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        color
        heading
        vragen {
          title: vraag
          content: antwoord
        }
      }
    }
  }
`;
